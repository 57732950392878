import uuid from 'react-uuid';

const SocialContainer = () => {
    const redes = [
        { 'red': 'instagram', 'url': 'https://www.instagram.com/lu4ult.hex/' },
        { 'red': 'facebook', 'url': 'https://facebook.com/lu4ult' },
        { 'red': 'tiktok', 'url': 'https://www.tiktok.com/@lu4ult' },
        { 'red': 'youtube', 'url': 'https://www.youtube.com/channel/UCRQEzTIztrR9x5i7I2q3hWA?sub_confirmation=1' },
        { 'red': 'whatsapp', 'url': 'https://wa.me/message/X5HGD7IKFNNSE1' },
        { 'red': 'telegram', 'url': 'https://t.me/lu4ult' },
        { 'red': 'email', 'url': 'mailto:lu4ult@gmail.com?subject=Hola! Vengo del sitio web' }
    ];

    return (
        <>
            {
                redes.map(red => {
                    return (
                        <a target='_blank' rel="noopener noreferrer" href={red.url} key={uuid()}>
                            <img src={`/img/social/${red.red}.png`} alt={`Logo ${red.red}`}></img>
                        </a>
                    );
                })
            }
        </>
    );
}

export default SocialContainer;