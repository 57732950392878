import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <a href={`https://wa.me/542954692293?text=${encodeURIComponent('Hola! Vengo de *LU4ULT - Tienda Online*')}`} target="_blank" rel="noopener noreferrer">Contacto</a>
            <a href="https://lu4ult.github.io/" target="_blank" rel="noopener noreferrer">Desarrollado por LU4ULT</a>
            <Link to="/instructivo">Cómo usar la tienda</Link>
        </footer>
    );
}

export default Footer;