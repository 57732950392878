import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import Item from "./Item"

const ItemList = ({ productos }) => {
    const productosTodos = [...productos].sort((a, b) => b.popularidad - a.popularidad);
    const [productosListados, setProductosListados] = useState(productos.sort((a, b) => b.popularidad - a.popularidad));

    const [esFiltrarSinStock, setEsFiltrarSinStock] = useState(false);
    const [textoFiltrar, setTextoFiltrar] = useState('');

    useEffect(() => {
        let producostFiltrados = [...productosTodos];
        if (esFiltrarSinStock) {
            producostFiltrados = producostFiltrados.filter(pr => pr.stock > 0)
        }
        if (textoFiltrar.length) {
            producostFiltrados = producostFiltrados.filter(pr => pr.title.toLowerCase().includes(textoFiltrar));
        }

        // producostFiltrados.sort((a, b) => b.popularidad - a.popularidad);
        setProductosListados(producostFiltrados);
    }, [esFiltrarSinStock, textoFiltrar]);

    // useEffect(() => {
    //     console.log(textoFiltrar);
    // }, [textoFiltrar]);



    //productosListados = productos;
    //productos.sort((a, b) => b.popularidad - a.popularidad);        //Ordenamos el array por "Popularidad" que es la cantidad de ventas


    const handleInputsChange = (e) => {
        if (e.target.type === 'checkbox') {
            setEsFiltrarSinStock(e.target.checked);
            //filtrarPorStock = e.target.checked;
        }

        if (e.target.type === 'text') {
            setTextoFiltrar(e.target.value.toLowerCase());
            //textoBuscado = e.target.value;
        }
    }


    return (
        <>
            <div className="productsFilterContainer">
                <span>
                    <label><input type="checkbox" onChange={handleInputsChange} id="filtros-enstock"></input>En Stock</label>
                </span>
                <input onChange={handleInputsChange} placeholder="Buscar" id="filtros-buscar"></input>
            </div>

            <div className="productsContainer">
                {
                    productosListados.map((producto) => {
                        return (
                            <Item producto={producto} key={uuid()}/>
                        )
                    })
                }
            </div>
        </>
    );
}

export default ItemList;