import { Link, NavLink } from "react-router-dom";
import { useContext, useEffect } from "react";
import { contexto } from "./CustomProvider";

import img1 from '../img/instructivo/1.webp'
import img2 from '../img/instructivo/2.webp'
import img3 from '../img/instructivo/3.webp'
import img4 from '../img/instructivo/4.webp'
import { iconoUsuario } from "./Iconos";

const Instructivo = () => {
    const { datosUsuarioContext } = useContext(contexto);

    let itemsEnCarrito = 0;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    if (datosUsuarioContext.sub !== undefined) {
        itemsEnCarrito = datosUsuarioContext.carrito?.length || 0;
    }

    return (
        <div className="instructivo">
            <h4>Instrucciones para utilizar la tienda:</h4>
            <ol>
                <li className={datosUsuarioContext.sub === undefined ? null : 'tarea-completada'}>
                    <NavLink to="/user"><strong>1)</strong> Iniciá sesión, para ello accedé a la sección "perfil" {iconoUsuario} </NavLink>
                    <img src={img1} alt="instructivo paso 1"></img>
                </li>
                <li className={datosUsuarioContext.correo ? 'tarea-completada' : null}>
                    <NavLink to="/user"><strong>2)</strong> Completá tus datos postales para el envío</NavLink>
                    <img src={img2} alt="instructivo paso 2"></img>
                </li>
                <li className={itemsEnCarrito === 0 ? null : 'tarea-completada'}>
                    <NavLink to="/"><strong>3)</strong> Elegí tus productos y armá el carrito</NavLink>
                    <img src={img3} alt="instructivo paso 3"></img>
                </li>
                <li>
                    <NavLink to="/carrito"><strong>4)</strong> Presiona "Finalizar Compra"</NavLink>
                    <img src={img4} alt="instructivo paso 4"></img>
                </li>
                <li><NavLink to="/user">¡Listo! Resta esperar nuestra respuesta por email en la que te detallaremos cómo continuar con los medios de pago. También, podes contactarnos por whatsapp; desde el ícono en la orden de compra en tu perfil.</NavLink></li>
            </ol>

            <h4>FAQ: (preguntas frecuentes)</h4>
            <ol>
                <li><details><summary>Envío gratis</summary>El costo del envío se calcula automáticamente en el carrito. A medida que agregas productos el costo de envío se va reduciendo</details></li>
                <li><details><summary>Transporte</summary>De momento la única opción de envío es Andreani pero pronto agregaremos más como Correo Argentino</details></li>
                <li><details><summary>Pagos</summary>Luego de creada la orden de compra nosotros te contactaremos por email para realizar el pago, las opciones son Link de Pago (Mercado Pago), transferencia bancaria, eCheq.</details></li>
                <li><details><summary>Factura</summary>Podemos emitir factura C. Si vas a necesitar la factura es muy importante que antes de realizar la compra cargues el CUIT y condición frente al IVA en tu perfil.<br></br>Si estas comprando para una empresa, carga el CUIT a quien debemos emitir, no el tuyo. Cualquier duda escribinos!!</details></li>
                <li><details><summary>Orden de pago contra factura</summary>Si necesitas que te generemos la factura para que puedan emitir la orden de pago escribinos a whatsapp para coordinar!</details></li>
                <li><details><summary>¿Qué es un producto full?</summary>Algunas de las publicaciones tienen la etiqueta "full" <strong><Link to="/producto/117">por ejemplo esta</Link></strong><br></br>
                    Significa que el producto está físicamente en los depósitos de Mercado Libre y ellos se encargan del envío, por eso la compra sólo se puede realizar en Mercado Libre.
                    Por esto hay publicaciones "iguales" pero a distinto precio, <strong><Link to="/producto/119">por ejemplo esta</Link></strong>
                </details></li>
            </ol>

        </div>
    );
}

export default Instructivo;