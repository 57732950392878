import md5 from 'md5'
import uuid from 'react-uuid';
import { useState, useEffect } from 'react'
import { doc, collection, getDocs, setDoc, deleteDoc, serverTimestamp, addDoc } from "firebase/firestore";
import { db } from './Firebase';
import { iconoClipboard, iconoDescargaArchivo, iconoEditar, iconoEmail, iconoFloppyDisk, iconoGuardarArchivo, iconoPlus, iconoTrash, iconoWhatsapp } from './Iconos';
import { fechaTimestampAHumano, haceCuantoTiempoStr, monospace } from './utils';
import { Confirm, Notify } from 'notiflix';
import { crearEtiquetaEnvio } from './correos';
import ModalEditandoOrden from './ModalEditandoOrden';
//import PdfFunction from './PdfFunction';


const AdminPage = () => {
    const [adminLogueado, setAdminLogueado] = useState(false);
    const [debeMostrarSoloLogueados, setDebeMostrarSoloLogueados] = useState(false);
    const [listadoOrdenes, setListadoOrdenes] = useState([]);
    const [volverATraerOrdenes, setVolverATraerOrdenes] = useState(false);
    const [listadoProductos, setListadoProductos] = useState([]);
    const [listadoUsuarios, setListadoUsuarios] = useState([]);
    const [editandoOrden, setEditandoOrden] = useState({});

    const opcionesEstadoCompra = ["Procesando", "Pendiente de pago", "En camino", "Finalizada", "Cancelar", "Cancelada"];


    useEffect(() => {
        if (adminLogueado === false)
            return;

        const ordersColection = collection(db, "ordenes", "");
        getDocs(ordersColection)
            .then(snapshot => {
                let lista = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                lista.sort((a, b) => b.fecha - a.fecha);
                setListadoOrdenes(lista);
            })
    }, [adminLogueado, volverATraerOrdenes, editandoOrden]);

    const handleLogin = () => {
        let user = document.getElementById("adminLogin-user").value.toLowerCase();
        let pass = document.getElementById("adminLogin-pass").value.toLowerCase();
        let hash = md5(user + pass + '1b34dcaa-a6d6-ed0b-fe82-874c784fb914');


        if (hash === '8268560ab68fff58103893867b0c5a80') {
            setAdminLogueado(true);
            setTimeout(() => { setAdminLogueado(false) }, 15 * 60 * 1000);

            const productosColection = collection(db, "productos", "");
            getDocs(productosColection)
                .then(snapshot => {
                    let lista = snapshot.docs.map(doc => ({ ...doc.data() }));
                    setListadoProductos(lista);
                })
        }
        else {
            alert("contraseña incorrecta");
        }
    }

    useEffect(() => {
        let algoModificado = false;

        const ordenesCopia = [...listadoOrdenes];
        ordenesCopia.forEach(async orden => {
            let analizarTn;
            if (orden.trackingNumber !== undefined) {
                analizarTn = orden.trackingNumber.substring(0, 5);
            }

            if (analizarTn === '36000' || analizarTn === 'g0000') {
                let response = await fetch(`https://apidestinatarios.andreani.com/api/envios/${orden.trackingNumber}/trazas`)
                let data = await response.json();

                // console.log(data)
                if (data.code === 404) {
                    alert(`Error en TN de id ${orden.id}. Borrar objeto desde FireStore.`);
                    alert(`Error en TN de id ${orden.id}. Borrar objeto desde FireStore.`);
                }
                else {
                    orden.andreani = [...data];
                    algoModificado = true;
                }
            }
        })

        //Esto se debería resolver con un Promise.all pero ya fue
        setTimeout(() => {
            if (algoModificado) {
                setListadoOrdenes(ordenesCopia);
            }
        }, 1000);
    }, [listadoOrdenes.length]);

    // useEffect(() => {
    //     listadoOrdenes.forEach(ord => {
    //         console.log(ord.andreani);
    //     })
    // }, [listadoOrdenes])

    useEffect(() => {
        //console.log(debeMostrarSoloLogueados);
        if (debeMostrarSoloLogueados === true) {
            let copiaUsuarios = [...listadoUsuarios];
            copiaUsuarios = copiaUsuarios.filter(user => user.sub !== undefined);

            setListadoUsuarios(copiaUsuarios);
        }
        else {
            const usersCollection = collection(db, "usuarios", "");
            getDocs(usersCollection)
                .then(snapshot => {
                    let lista = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    // lista.forEach(e => {
                    //     if (e.last_update === undefined) {
                    //         e.last_update = { 'seconds': 0 };
                    //     }
                    // })

                    lista.map(e => e.date = new Date(e.last_update?.seconds * 1000 || 0));
                    lista.sort((a, b) => b.date - a.date);

                    /*
                    lista.map(e => e.date = new Date(e.last_update?.seconds * 1000 || 0))
                    let listaABorrar = lista.filter(e => e.date < new Date('2023-07-01T00:00:00.0Z') && e.id.length > 35)

                    console.log("Reducida:", listaABorrar.length)
                    console.log(listaABorrar)
                    listaABorrar.forEach(user => {
                        console.log(user.id)
                        deleteDoc(doc(db, "usuarios", user.id));
                    });
                    */

                    setListadoUsuarios(lista);
                })
        }
    }, [debeMostrarSoloLogueados]);


    const actualizarOrden = (id) => {
        const listadOrdenesCopia = listadoOrdenes.filter(el => el.id !== id);
        const ordenAModificar = listadoOrdenes.find(el => el.id === id)
        const estadoActual = document.getElementById("estado" + id).value;
        const tn = document.getElementById("tn" + id).value.replace(' ', '').substring(0, 23);

        if (tn.length < 15) {
            alert("tn demasiado corto")
        }
        else {
            ordenAModificar.trackingNumber = tn;
        }

        ordenAModificar.estado = estadoActual;

        setListadoOrdenes([ordenAModificar, ...listadOrdenesCopia]);

        //while(ordenAModificar.andreani length > 1 ) ordenAModificar.andreani.pop
        setDoc(doc(db, "ordenes", id), ordenAModificar, { merge: true });
    }

    const actualizarProducto = (producto) => {
        //TODO: referente a react para que se actualice
        let cantidad = document.getElementById('cantidad-' + producto.id).value || 0;

        let productoAModificar = { ...producto };
        productoAModificar.stock = parseInt(cantidad);
        //console.log(productoAModificar)
        setDoc(doc(db, "productos", productoAModificar.id.toString()), productoAModificar, { merge: true });
    }

    const handleOnFileChange = (e) => {
        let archivo = e.target.files[0];
        let lector = new FileReader();
        lector.onload = (f) => {
            let contenido = JSON.parse(f.target.result);
            contenido = contenido.sort((a, b) => a.id - b.id);

            contenido.forEach(producto => {
                setDoc(doc(db, "productos", producto.id.toString()), producto, { merge: true });
            })
            setListadoProductos(contenido);
        };
        lector.readAsText(archivo);

    }

    const eliminarUsuario = (usuario) => {
        Confirm.show(
            'Seguro??',
            `Eliminar usuario`,
            'Si, eliminar',
            'No,mantenerla',
            () => {
                deleteDoc(doc(db, "usuarios", usuario));
                console.log(listadoUsuarios)
                let listadoUsuariosReducida = listadoUsuarios.filter(user => user.id !== usuario)
                console.log(listadoUsuariosReducida)
                setListadoUsuarios([...listadoUsuariosReducida]);
            }
        );
    }

    const eliminarOrden = (orden) => {
        Confirm.show(
            'Seguro??',
            `Vas a eliminar esta orden`,
            'Si, eliminar',
            'No,mantenerla',
            async () => {
                await deleteDoc(doc(db, "ordenes", orden.id));
                setVolverATraerOrdenes(!volverATraerOrdenes);
            }
        );
    }

    const descargarEtiquetaCorreo = (orden) => {
        Confirm.prompt(
            'Seleccionar Servicio de envío',
            'Paq Ar / Andreani / Buspack',
            'Paq Ar',
            'Generar',
            'Cancelar',
            (clientAnswer) => {
                //Usamos try para alertar si el usuario no colocó por ejemplo la provincia, podríamos hacer alguna validación pero es algo crítico
                try {
                    crearEtiquetaEnvio(orden, clientAnswer.toLowerCase().replace(' ', ''));
                }
                catch (e) {
                    Notify.failure(`${e}`);
                }
            }
        );
    }

    const handleBuscarUsuario = (e) => {
        let busqueda = e.target.value.toLowerCase();
        let usuariosCopia = [...listadoUsuarios];

        if (!busqueda) {
            setDebeMostrarSoloLogueados(!debeMostrarSoloLogueados);  //Este tiene un useEffect que llama directamente a firestore.
        }
        else {
            usuariosCopia = usuariosCopia.filter(e => e.name?.toLowerCase().includes(busqueda));
            setListadoUsuarios(usuariosCopia);
        }
    }

    const handleClipboardClick = (cuit) => {
        if (navigator.clipboard?.writeText) {
            navigator.clipboard.writeText(cuit);
        }
    }

    const handlePdf = () => {
        //PdfFunction();
    }

    const crearOrdenAlUsuario = (usuario) => {
        const ordenDeCompra = {
            totalProductos: 1,
            totalCosto: 10000,
            costoEnvio: 0,
            productos: [{ cantidadDelPedido: 1, title: "Diseño PCB", imgMeliUrl: [{ secure_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCZag8IeMXUW4F1qtcgHLi3n33ZhB-fxc_lg&usqp=CAU" }] }],
            fecha: serverTimestamp(),
            estado: "Procesando",
            servicioEnvio: 'andreani',
            trackingNumber: `TN Pendiente`,
            user_sub: usuario.sub,
            usuario: { ...usuario }
        }

        Confirm.show(
            'Crear??',
            `Vas a crear una orden para este usuario`,
            'Si, crear',
            'Cancelar',
            () => {
                const coleccionCompras = collection(db, "ordenes");
                addDoc(coleccionCompras, ordenDeCompra)
                    .then((docRef) => {
                        setVolverATraerOrdenes(!volverATraerOrdenes);
                        alert(docRef.id);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    })
            }
        );
    }

    if (adminLogueado === false) {
        return (
            <div className="adminPage">
                <div className='adminPage__credenciales'>
                    <input id="adminLogin-user" type="text" placeholder="Usuario"></input>
                    <input id="adminLogin-pass" type="password" ></input>
                    <button type="submit" onClick={handleLogin}>Acceder</button>
                    {/* <button onClick={() => { handlePdf() }}>PDF</button> */}

                </div>
                {/* <PdfFunction /> */}
            </div>
        );
    }
    else {

        if (JSON.stringify(editandoOrden) !== '{}') {
            return (
                <div className="adminPage">
                    <ModalEditandoOrden orden={editandoOrden} setEditandoOrden={setEditandoOrden} />
                </div>
            );
        }

        return (
            <div className="adminPage">
                <div className='adminPage__section ordenes-container'>
                    {
                        listadoOrdenes.map((orden, indice) => {
                            return (
                                <details className={`orden ${indice % 2 ? "impar" : "par"}`} key={uuid()} open={orden.estado !== 'Finalizada' && orden.estado !== 'Cancelada'}>
                                    <summary>
                                        <img src={orden.usuario.picture} alt="Foto perfil"></img>
                                        <p className='nombreUsuario'>{orden.usuario.nombre}</p>
                                        <p>{orden.id}</p>
                                        {/* <p>{firestoreTimestampToHumanDate(orden.fecha)}</p> */}
                                        <p>{fechaTimestampAHumano(new Date(orden.fecha.seconds * 1000).getTime())}</p>

                                        {
                                            <div className='fotoProductosDeOrden'>
                                                {
                                                    orden.productos.map(pr => {
                                                        return (<img src={pr.imgMeliUrl[0].secure_url} key={uuid()}></img>);
                                                    })
                                                }
                                            </div>
                                        }
                                    </summary>

                                    <span>Medio de pago: {orden.medioDePago || '-'}</span>
                                    <details>
                                        <summary>AFIP</summary>
                                        <option>{orden.usuario.iva}</option>
                                        <option>{orden.usuario.cuit}</option>
                                        <button onClick={() => { handleClipboardClick(orden.usuario.cuit) }}>{iconoClipboard}</button>
                                    </details>


                                    <details open={orden.estado === 'Procesando'}>
                                        <summary>Productos</summary>
                                        {
                                            orden.productos.map(producto => {
                                                return (<option key={uuid()}>{producto.cantidadDelPedido}x {producto.title}</option>);
                                            })
                                        }
                                    </details>

                                    <input type="text" id={"tn" + orden.id} defaultValue={orden.trackingNumber}></input>


                                    {/* TODO: deshabilitar los botones de email/whatsapp según la respuesta del usuario */}
                                    {
                                        orden.andreani &&
                                        <a>
                                            <details open={orden.estado === 'En camino'}>
                                                <summary>Andreani Seguimiento</summary>
                                                <ol>
                                                    {
                                                        orden.andreani.map((evento, indice) => {
                                                            return (
                                                                <li key={uuid()} selected={indice === 0}>
                                                                    {evento.fecha.dia || "upss"} {evento.fecha.hora || "upss"}: {evento.evento || "upss"} {evento.estado}
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ol>
                                            </details>
                                        </a>
                                    }

                                    <select id={"estado" + orden.id} defaultValue={orden.estado}>
                                        {
                                            opcionesEstadoCompra.map(opc => {
                                                return (
                                                    <option key={uuid()} value={opc}>{opc}</option>
                                                )
                                            })
                                        }
                                    </select>

                                    <div className='botones-contenedor'>
                                        <button className={indice === 0 ? 'paqar' : null} disabled={orden.estado === 'Finalizada'} onClick={() => { descargarEtiquetaCorreo(orden) }}>{iconoGuardarArchivo}</button>
                                        <button className={indice === 0 ? 'email' : null} disabled={orden.usuario.trackCode === 'Whatsapp'} onClick={() => { window.open(`mailto: ${orden.usuario.correo}?subject=Tu compra en LU4ULT`); }} >{iconoEmail}</button>
                                        <button className={indice === 0 ? 'whatsappAdmin' : null} disabled={orden.usuario.trackCode === 'Email'} onClick={() => { window.open(`https://wa.me/+549${orden.usuario.codarea}${orden.usuario.cel}?text=Hola ${orden.usuario.nombre}!%0A%0ATe escribimos de LU4ULT para adjuntar el código de tracking de tu pedido:%0A*${orden.id}*%0A%0AEl cuál es: ${monospace}${orden.trackingNumber}${monospace}%0A%0ATambién podes encontrarlo en tu perfil en esta dirección:%0Ahttps://lu4ult.com/user%0A%0AGracias!`); }}>{iconoWhatsapp}</button>
                                        <button className={indice === 0 ? 'eliminar' : null} onClick={() => { eliminarOrden(orden) }} disabled={orden.estado === 'Finalizada'}>{iconoTrash}</button>
                                        <button className={indice === 0 ? 'pdf' : null} onClick={() => { window.open(`https://inicio-lu4ult.vercel.app/?orden=${orden.id}&fecha=${orden.fecha.seconds}`); }}>{iconoDescargaArchivo}</button>
                                        <button onClick={() => { setEditandoOrden(orden) }}>{iconoEditar}</button>
                                        <button className={indice === 0 ? 'guardar' : null} onClick={() => { actualizarOrden(orden.id) }}>{iconoFloppyDisk}</button>
                                    </div>
                                </details>
                            )
                        })
                    }
                </div>
                <input type="file" onChange={handleOnFileChange} id="archivo-selector"></input>
                <div className='adminPage__section productos-container'>
                    {
                        listadoProductos.map((pr, indice) => {
                            return (
                                <div className={`orden ${indice % 2 ? "impar" : "par"}`} key={uuid()}>
                                    <p>{pr.id}</p>
                                    <a href={`https://articulo.mercadolibre.com.ar/${pr.idMeli.replace('MLA', 'MLA-')}`} target="_blank">{pr.idMeli}</a>

                                    <input type="number" defaultValue={pr.stock} id={`cantidad-${pr.id}`} disabled={pr.fullFilment}></input>
                                    <p>{pr.internalCategory || "null"}</p>

                                    <p className='titulo'>{pr.title}</p>
                                    <p>{pr.weight || 0}</p>
                                    <p>{pr.fullFilment ? "Full" : ""}</p>
                                    <p></p>

                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <button className={indice === 0 ? 'guardar' : null} onClick={() => { actualizarProducto(pr) }}>{iconoFloppyDisk}</button>
                                </div>
                            )
                        })
                    }

                </div>

                <div className='adminPage__section usuarios-container'>
                    <label><input onChange={() => { setDebeMostrarSoloLogueados(!debeMostrarSoloLogueados) }} type="checkbox" checked={debeMostrarSoloLogueados}></input>Mostrar sólo usuario logueados [{listadoUsuarios.length}]</label>
                    <label><input type='text' onChange={handleBuscarUsuario}></input>Buscar usuario</label>
                    {
                        listadoUsuarios.map((user, indice) => {
                            return (
                                <details className={`orden ${indice % 2 ? "impar" : "par"}`} key={uuid()} open={indice === 0}>
                                    <summary>{user.nombre} {user.apellido} - {user.correo} - {user.ip_publica} - {fechaTimestampAHumano(user.date.getTime())} ({haceCuantoTiempoStr(new Date(user.last_update?.seconds * 1000) || 0)})</summary>
                                    {/* <p>{user.last_update.seconds}, {haceCuantoTiempoStr(new Date(), new Date(user.last_update.seconds * 1000))}</p> */}
                                    <p>{user.nombre}, {user.apellido}</p>
                                    <p>DNI: {user.dni}</p>
                                    <p>CEL: {user.codarea}{user.cel}</p>
                                    <p>{user.localidad}, {user.provincia}, {user.calle} {user.altura},{user.piso}{user.unidad} </p>
                                    <p>Notificar Andreani: {user.esNotificarAndreani === undefined ? 'no especifica' : JSON.stringify(user.esNotificarAndreani)}</p>
                                    <a href={user.ip_publica ? `https://ipinfo.io/${user.ip_publica}` : null} target="_blank" rel="noopener noreferrer">{user.ip_publica || "0.0.0.0"}</a>
                                    <div className="botones-contenedor">
                                        <button className={indice === 0 ? 'paqar' : null} onClick={() => { descargarEtiquetaCorreo({ usuario: { ...user } }) }}>{iconoGuardarArchivo}</button>
                                        <button className={indice === 0 ? 'email' : null} onClick={() => { window.open(`mailto: ${user.correo}?subject=Tu compra en LU4ULT`); }} >{iconoEmail}</button>
                                        <button className={indice === 0 ? 'whatsapp' : null} onClick={() => { window.open(`https://wa.me/+549${user.codarea}${user.cel}?text=Tu compra en LU4ULT`); }}>{iconoWhatsapp}</button>
                                        <button onClick={() => { eliminarUsuario(user.sub || user.id) }}>{iconoTrash}</button>
                                        <button className={indice === 0 ? 'crear-orden' : null} onClick={() => { crearOrdenAlUsuario(user) }} >{iconoPlus}</button>
                                        {/* <button className={indice === 0 ? 'guardar' : null}>{iconoFloppyDisk}</button> */}
                                    </div>
                                </details>
                            )
                        })
                    }
                </div>
            </div>);
    }
}
export default AdminPage;