import { iconoCarrito } from "./Iconos";
import { NavLink } from "react-router-dom"
import { useContext } from "react";
import { contexto } from "./CustomProvider"

const CartWidget = () => {
    const { totalProductos } = useContext(contexto);
    return (
        <NavLink to="/carrito" className="header__carrito">
            {iconoCarrito}
            <span>{totalProductos}</span>
        </NavLink>
    );
}

export default CartWidget;