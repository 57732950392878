export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export function NomPropio(strBk) {
    //TODO: separar por espacios y aplicar a cada palabra
    // let palabras = strBk.split(" ");
    // palabras.forEach(palabra => {console.log(palabra)})
    let str = strBk;
    str = str.toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str;
}

export const reemplazarTildes = (str) => {
    str = str.replace('á', 'a');
    str = str.replace('é', 'e');
    str = str.replace('í', 'i');
    str = str.replace('ó', 'o');
    str = str.replace('ú', 'u');

    //str = str.reaplce('ñ', 'n');

    return str;
}

export const obtenerPrimeraPalabraComoStr = (str) => {
    let palabras = str.split(" ");
    return palabras[0] + " " + palabras[1];
}


export const formateaMoneda = (numero) => {
    const options = {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: Number.isInteger(numero) ? 0 : 2
    };

    return numero.toLocaleString("es-AR", options);
}

export const notiflixPersonalizacion = () => {
    if (window.innerWidth > 1200) {
        return { distance: "100px", showOnlyTheLastOne: true, clickToClose: true };
    }
    return { position: "center-bottom", showOnlyTheLastOne: true, clickToClose: true };
}

export const fechaFirestoreAFechaJS = (serverTimestamp) => {
    return new Date(serverTimestamp.seconds * 1000);
}

export const fechaTimestampAHumano = (timestamp) => {
    if (typeof (timestamp) !== 'number')
        return typeof (timestamp);

    const fecha = new Date(timestamp);

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const txt = `${fecha.getDate()} de ${meses[fecha.getMonth()]}, ${fecha.toLocaleTimeString()}`;

    return txt;
}

export const esProduccion = () => {
    return window.location.origin === 'https://lu4ult.com';
}

export const isMobile = () => {
    return window.innerWidth < 768;
}

export const monospace = '```';


export const notificarMePorWhatsapp = (mensaje) => {
    const urlWhatsapp = new URL('https://api.callmebot.com/whatsapp.php');
    urlWhatsapp.searchParams.set('text', mensaje);
    urlWhatsapp.searchParams.set('phone', '+5492954692293');
    urlWhatsapp.searchParams.set('apikey', '727958');

    fetch(urlWhatsapp)
        .catch(error => console.log(error))

    const urlNotificador = new URL('https://blynk.cloud/external/api/update');
    urlNotificador.searchParams.set('token', '40faYKApg37ONbh8YIPQWLe5NcdDRtBu');
    urlNotificador.searchParams.set('v27', `Tienda:   ${mensaje}`);

    fetch(urlNotificador)
}

export const haceCuantoTiempoStr = (fecha1, fecha2) => {
    //Si pasaron sólo una fecha hay que comparar con la hora actual.
    if (typeof (fecha1) === 'object' && fecha2 === undefined) {
        fecha2 = fecha1;
        fecha1 = new Date();
    }

    if (typeof (fecha1) !== 'object')
        return 'fecha 1 no es objeto';

    if (typeof (fecha2) !== 'object')
        return 'fecha 2 no es objeto';

    const esFuturoOPasado = fecha1 < fecha2 ? 'En' : 'Hace';
    const diferenciaTiempoMinutos = parseInt(Math.abs((fecha1 - fecha2)) / 60000);

    if (diferenciaTiempoMinutos > 3 * 24 * 60)
        return `${esFuturoOPasado} ${parseInt(diferenciaTiempoMinutos / (24 * 60))} días`;

    const txt = diferenciaTiempoMinutos === 0 ? 'Ahora' : diferenciaTiempoMinutos >= 60 ? `${esFuturoOPasado} ${parseInt(diferenciaTiempoMinutos / 60)} hora${parseInt(diferenciaTiempoMinutos / 60) > 1 ? 's' : ''}` : `${esFuturoOPasado} ${diferenciaTiempoMinutos} minuto${diferenciaTiempoMinutos > 1 ? 's' : ''}`;
    return txt;
}