import { NavLink } from "react-router-dom";
import { iconoUsuario } from "./Iconos";
import { contexto } from "./CustomProvider";
import { useContext } from "react";

const UserLogin = () => {
    const { datosUsuarioContext } = useContext(contexto);
    const nombre = datosUsuarioContext.nombre || "Perfil";

    return (
        <NavLink className="header__userLogin" to="/user">
            {iconoUsuario}
            <span className="user__name">{nombre}</span>
        </NavLink>
    );
}

export default UserLogin;