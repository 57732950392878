import { createContext, useState, useEffect } from "react"
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./Firebase";
import { useAuth0 } from "@auth0/auth0-react";
import uuid from "react-uuid";
import { esProduccion } from "./utils";

export const contexto = createContext();
const Provider = contexto.Provider;

const CustomProvider = ({ children }) => {
    const { user, isAuthenticated } = useAuth0();
    const [timeOutId, setTimeOutId] = useState();
    const [totalProductos, setTotalProductos] = useState(0);
    const [carrito, setCarrito] = useState([]);
    const [productosTodos, setProductosTodos] = useState([]);
    const [datosUsuarioContext, setDatosUsuarioContext] = useState({});

    useEffect(() => {
        if (esProduccion()) {
            setTimeOutId(() => {
                fetch('https://api.ipify.org/?format=json')
                    .then(response => response.json())
                    .then(data => {
                        const copiaUsuario = { ...datosUsuarioContext };
                        copiaUsuario['ip_publica'] = data['ip'] || "0.0.0.0";
                        copiaUsuario['last_update'] = serverTimestamp();
                        setDatosUsuarioContext({ ...copiaUsuario });
                        //guardarUsuarioEnFirestore({ ...datosUsuario, ip_publica: data['ip'] })
                    })
                    .catch(error => console.log(error))
            }, 5000);
        }
    }, [])


    useEffect(() => {
        if (isAuthenticated) {
            let identificadorDelUsuario = user.sub;
            console.log(identificadorDelUsuario)
            localStorage.setItem('tiendaLu4ult-userId', JSON.stringify(user.sub));
        }
        if (isAuthenticated === true && datosUsuarioContext.correo === undefined) {             //Cuando se accede al carrito sin pasar por /user no cargó los datos del usuario, por lo que correo es undefined
            const biciRef = doc(db, "usuarios", user.sub);
            getDoc(biciRef).then(snapshot => {
                if (snapshot.exists()) {
                    setDatosUsuarioContext({ ...snapshot.data() });
                    if (snapshot.data().carrito.length) {
                        setTimeOutId(() => {
                            //console.log([...snapshot.data().carrito])
                            setCarrito([...snapshot.data().carrito]);
                        }, 500);
                    }
                    //console.log("push to context");
                }
            })
        }

        //TODO: terminar esto, chequear la caja boba
        if (isAuthenticated === false && localStorage.getItem('tiendaLu4ult-userId') !== undefined) {
            console.log("Id por data local")
            //identificadorDelUsuario = JSON.parse(localStorage.getItem('cajaBoba-userId'));
        }
    }, [isAuthenticated]);


    useEffect(() => {
        if (JSON.stringify(datosUsuarioContext) !== "{}") {
            clearTimeout(timeOutId)
            const temporizadorId = setTimeout(() => {
                const userIdentificador = datosUsuarioContext.sub || JSON.parse(localStorage.getItem('tiendaLu4ult-randomID'));
                setDoc(doc(db, "usuarios", userIdentificador), { ...datosUsuarioContext, 'last_update': serverTimestamp() }, { merge: true });

            }, 1000);

            setTimeOutId(temporizadorId);
        }
    }, [datosUsuarioContext]);


    useEffect(() => {
        let carritoCopia = [...carrito];

        if (carritoCopia.length) {
            carritoCopia.forEach(e => {
                const stock = productosTodos.find(f => f.id === e.id)

                if (stock !== undefined && e.cantidadIndividual > stock.stock) {
                    console.log('algo va mal')
                    console.log(e)
                    e.cantidadIndividual = stock.stock;
                }
            })
        }

        setTotalProductos(carritoCopia.reduce((acc, current) => acc + current.cantidadIndividual, 0));


        // console.table(carritoCopia)
        // console.table(productosTodos)

        const copiaUsuario = { ...datosUsuarioContext };
        copiaUsuario.carrito = [...carritoCopia];

        //Si no está logueado le guardaoms el carrito en local y además le generamos un random ID para cargarlo en firestore
        if (datosUsuarioContext.sub === undefined) {
            localStorage.setItem('tiendaLu4ult-carrito', JSON.stringify(carritoCopia));

            const randomIdLocal = localStorage.getItem('tiendaLu4ult-randomID');
            if (randomIdLocal === null) {
                localStorage.setItem('tiendaLu4ult-randomID', JSON.stringify(uuid()));
            }
        }

        setDatosUsuarioContext(copiaUsuario);
    }, [carrito]);


    function estaProductoEnCarrito(id) {
        const indiceHallado = carrito.findIndex(el => el.id === id)

        return indiceHallado >= 0;
    }

    const borrarItemDelCarrito = (productoABorrar) => {
        const carritoCopia = [...carrito].filter(pr => pr.id !== productoABorrar.id);
        setCarrito([...carritoCopia]);
    }

    const agregarAlCarrito = (producto) => {
        if (estaProductoEnCarrito(producto.id) === false) {
            setCarrito([...carrito, producto]);
        }
        else {
            const carritoCopia = [...carrito];
            const productoAModificar = carritoCopia.find(el => el.id === producto.id)

            productoAModificar.cantidadIndividual += producto.cantidadIndividual;
            setCarrito([...carritoCopia]);
        }
    }

    const valorDelContexto = {
        carrito: carrito,
        totalProductos: totalProductos,
        agregarAlCarrito: agregarAlCarrito,
        borrarItemDelCarrito: borrarItemDelCarrito,
        productosTodos: productosTodos,
        setProductosTodos: setProductosTodos,
        datosUsuarioContext: datosUsuarioContext,
        setDatosUsuarioContext: setDatosUsuarioContext
    }

    return (
        <Provider value={valorDelContexto}>
            {children}
        </Provider>
    );
}

export default CustomProvider;