import logo from "../img/logo.png"
import UserLogin from "./UserLogin"
import { Link, NavLink } from "react-router-dom"
import SocialContainer from "./SocialContainer";
import CartWidget from "./CartWidget";
import { iconoMasInfo } from "./Iconos";


const Header = () => {
    return (
        <header>
            <div className="header__logo">
                <Link to="/" className="header__logo">
                    <img src={logo} alt="LU4ULT logo"></img>
                </Link>
            </div>

            <div className="header__social-container">
                <SocialContainer />
            </div>

            <div className="header__iconos">
                <div className="header__iconos-info">
                    <NavLink to="/instructivo">
                        {iconoMasInfo}
                        <span>Cómo usar la página</span>
                    </NavLink>

                </div>

                <div className="header__iconos-carrito">
                    <CartWidget />
                </div>

                <div className="header__iconos-user">
                    <UserLogin />
                </div>
            </div>
        </header>
    );
}

export default Header;