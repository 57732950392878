import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { iconoLogin } from "./Iconos";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button className="loginButtonContainer" onClick={() => loginWithRedirect()}>
      {iconoLogin}
      Log In
    </button>
  )
};

export default LoginButton;