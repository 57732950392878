import { Navigate, NavLink } from "react-router-dom";

const Error = () => {
    const ruta = window.location.href;

    if (ruta.includes('/productos/')) {
        if (ruta.includes('rai')) {
            Navigate({ 'to': '/producto/132?share=redireccion' });
        }

        if (ruta.includes('dimmer')) {
            Navigate({ 'to': '/producto/119?share=redireccion' });
        }
    }
    return (
        <NavLink to="/" className="error404-img">
            <img alt="Imagen de error: pagina no encontrada" src="https://img.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_114360-5529.jpg"></img>
        </NavLink>
    );
}

export default Error;