import { useState } from "react";
import Input from "./Input";
import uuid from "react-uuid";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./Firebase";
import { Loading, Notify } from "notiflix";

const ModalEditandoOrden = ({ orden, setEditandoOrden }) => {
    const [valores, setValores] = useState({
        totalCosto: orden.totalCosto,
        costoEnvio: orden.costoEnvio,
        imagen: orden.productos[0].imgMeliUrl[0].secure_url,
        titulo: orden.productos[0].title,

        productos: [{ title: orden.productos[0].title, imgMeliUrl: [{ secure_url: orden.productos[0].imgMeliUrl[0].secure_url }] }]
    });
    // const [valoresProductos, setValoresProductos] = useState();
    const productos = orden['productos'];

    const arrayDeKeys = [
        { 'titulo': 'Precio (productos+envios)', 'key': 'totalCosto', 'tipo': 'number' },
        { 'titulo': 'Costo sólo envio', 'key': 'costoEnvio', 'tipo': 'number' },
        { 'titulo': 'Titulo Orden', 'key': 'titulo', 'tipo': 'string' },
        { 'titulo': 'Imágen', 'key': 'imagen', 'tipo': 'string' },
    ];


    const handleChange = (e) => {
        const key = e.target.name;
        let value;
        if (e.target.type === 'checkbox')
            value = e.target.checked;
        if (e.target.type === 'text')
            value = e.target.value;
        if (e.target.type === 'number')
            value = parseInt(e.target.value);
        // if (e.target.type === 'datetime-local')
        //     value = fechaParaInput(new Date(e.target.value))

        let valoresCopia = { ...valores };
        valoresCopia[key] = value;

        if (key === 'titulo') {
            // console.log(e.target.value)
            valoresCopia['productos'][0]['title'] = e.target.value;
        }

        if (key === 'imagen') {
            // console.log(e.target.value)
            valoresCopia['productos'][0]['imgMeliUrl'][0]['secure_url'] = e.target.value;
        }
        // console.log(valoresCopia)

        setValores(valoresCopia);
    }

    const handleGuardar = () => {
        Loading.hourglass();
        let ordenEditada = { ...valores };

        delete ordenEditada['titulo'];
        delete ordenEditada['imagen'];

        setDoc(doc(db, "ordenes", orden.id), ordenEditada, { merge: true })
            .then(() => {
                setEditandoOrden({});
                Loading.remove();
            })
    }

    return (
        <div className="adminPage__section modalEdicionContainer">
            <div className="modalConfiguracion">
                <button className="cerrar" onClick={() => { setEditandoOrden({}) }}>X</button>
                {
                    arrayDeKeys.map(k => {
                        return (<Input
                            key={uuid()}
                            props={k}
                            valores={valores}
                            handleChange={handleChange} />)
                    })
                }
                <ul>
                    {

                        productos.map(producto => {
                            return (
                                <li key={uuid()}>
                                    <input value={producto.id}></input>
                                    <input value={producto.cantidadDelPedido}></input>
                                    <input value={producto.price}></input>
                                    <input value={producto.title}></input>
                                    <input value={producto.imgMeliUrl[0].secure_url}></input>
                                </li>
                            );
                        })
                    }
                </ul>

            </div>
            <button className="guardar" onClick={handleGuardar}>GUARDAR</button>
        </div>
    );
}

export default ModalEditandoOrden;