import uuid from 'react-uuid';
import { useContext, useState } from "react";
import { contexto } from "./CustomProvider";
import CarritoItem from './CarritoItem';
import { useAuth0 } from "@auth0/auth0-react";

import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import { db } from "./Firebase";
import { Loading, Report } from 'notiflix';
import { formateaMoneda, esProduccion, notificarMePorWhatsapp, fechaTimestampAHumano } from './utils';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import CaraTristeAnimacion from './CaraTristeAnimacion';
import paqArLogo from '../img/paqar.webp'
import andreaniLogo from '../img/andreani.webp'

const mediosDePagoDisponibles = ["MercadoPago (link de pago)", "Transferencia Bancaria", "eCheque"];

const CarritoContainer = () => {
    const { isAuthenticated } = useAuth0();
    const { carrito, productosTodos, totalProductos, vaciarCarrito, datosUsuarioContext } = useContext(contexto);

    const [proveedorDeCorreo, setProveedorDeCorreo] = useState('andreani');
    const [medioDePago, setMedioDePago] = useState(mediosDePagoDisponibles[0]);
    const [seModificoMedioPago, setSeModificoMedioPago] = useState(false);

    if (productosTodos.length === 0) {
        window.location.replace(window.location.origin);
    }


    let precioEnvioGratis = 1150 + 120 * 2;
    if (proveedorDeCorreo === 'andreani') {
        precioEnvioGratis = 1300 + 120 * 2;
    }

    let preciosCarrito;
    let costoCarrito;
    if (JSON.stringify(carrito) != '[]') {
        preciosCarrito = carrito.map(item => {
            const producto = productosTodos.find(pr => pr.id === item.id)
            return producto.price * item.cantidadIndividual;
        });
        costoCarrito = preciosCarrito.reduce((a, b) => (a + b), 0);
    }

    let descuentosCarrito = carrito.map(e => e.freeShipping)
    let pesosCarrito = carrito.map(e => e.weight * e.cantidadIndividual);

    //let pesoTotalCarrito = pesosCarrito.reduce((a, b) => (a + b), 0)
    let costoDelEnvioGratis = Math.floor(precioEnvioGratis - costoCarrito * 0.05 - Math.max(...descuentosCarrito) / 2);

    if (costoDelEnvioGratis < 200) {
        costoDelEnvioGratis = 0;
    }



    const handleFinalizarCompra = () => {
        Loading.hourglass();

        document.getElementById("botonFinalizarCompra").disabled = true;

        let textoItemsComprados = "";
        //ordenDeCompra.productos.forEach(item => {  });

        let productosComprados = [];
        carrito.forEach(item => {
            let producto = productosTodos.find(e => e.id === item.id);
            producto.cantidadDelPedido = item.cantidadIndividual;
            delete producto.info;
            delete producto.popularidad;
            delete producto.stock;
            delete producto.video;
            delete producto.fullFilment;


            while (producto.imgMeliUrl.length > 1) {
                producto.imgMeliUrl.pop();
            }
            delete producto.imgMeliUrl[0].id;
            delete producto.imgMeliUrl[0].max_size;
            delete producto.imgMeliUrl[0].quality;
            delete producto.imgMeliUrl[0].size;
            delete producto.imgMeliUrl[0].url;

            textoItemsComprados += `${producto.cantidadDelPedido}x ${producto.title}\n`
            productosComprados.push(producto);
        });

        //console.log(textoItemsComprados)

        const ordenDeCompra = {
            totalProductos: totalProductos,
            totalCosto: costoCarrito + costoDelEnvioGratis,
            costoEnvio: costoDelEnvioGratis,
            medioDePago: medioDePago,
            productos: productosComprados,
            fecha: serverTimestamp(),
            estado: "Procesando",
            servicioEnvio: 'andreani',
            trackingNumber: `TN ${'Andreani'} Pendiente`,
            user_sub: datosUsuarioContext.sub,
            usuario: { ...datosUsuarioContext }

        }

        //console.log(productosComprados)
        const coleccionCompras = collection(db, "ordenes");
        addDoc(coleccionCompras, ordenDeCompra)
            .then((docRef) => {

                if (esProduccion()) {
                    emailjs.send('service_k3tj0b9', 'template_aznyypc', {
                        'destinatario': datosUsuarioContext.correo,
                        'fecha': fechaTimestampAHumano(new Date().getTime()),
                        'id_pedido': docRef.id,
                        'from_name': datosUsuarioContext.nombre,
                        'total_productos': totalProductos,
                        'total_costo': formateaMoneda(costoCarrito + costoDelEnvioGratis),
                        'address': `${datosUsuarioContext.calle} ${datosUsuarioContext.altura}, ${datosUsuarioContext.localidad} ${datosUsuarioContext.provincia}`,
                        'productos': textoItemsComprados
                    }, '840utIXux0aomLktd');
                }
                // vaciarCarrito();

                Loading.remove(3000);
                setTimeout(() => {
                    Report.info(
                        '¡Gracias!',
                        `Comenzamos a trabajar en tu orden ${docRef.id}. Pronto te contactaremos por email para realizar el pago. Si te arrepentís de la compra, podes cancelarla desde tu perfil.`,
                        'Finalizar',
                        () => {

                            window.location.replace(window.location.origin + "/user")
                        }
                    );
                    navigator.vibrate(500);

                }, 2000);

                if (esProduccion()) {
                    notificarMePorWhatsapp(`Compra de ${datosUsuarioContext.nombre} por ${costoCarrito} $`);
                }
            })
    }

    return (
        <div className="carritoContainer">
            <div className='productos'>
                {
                    JSON.stringify(carrito) === "[]" ? <CaraTristeAnimacion mensaje="Sin productos" />
                        : carrito.map(item => {
                            return (
                                <CarritoItem key={`carritoitem${item.id}`} item={item} />
                            )
                        })
                }

                {
                    JSON.stringify(carrito) === "[]" ? <></>
                        : <div className={`${costoDelEnvioGratis === 0 ? "envioGratis" : ""} productos__producto`}>
                            <img alt='Producto' src={proveedorDeCorreo === 'paqar' ? paqArLogo : andreaniLogo}></img>
                            <label>{proveedorDeCorreo === 'paqar' ? 'Envio Paq Ar (Correo Argentino)' : 'Envío por Andreani'}</label>
                            {/* <select className='selectorProveedorEnvio' onChange={(event) => { setProveedorDeCorreo(event.target.value) }} value={proveedorDeCorreo}>
                                <option disabled value="paqar">Paq Ar (Correo Argentino)</option>
                                <option value="andreani">Andreani</option>
                            </select> */}
                            <div></div>
                            <p>{formateaMoneda(costoDelEnvioGratis)}</p>
                            <button disabled="true"></button>
                        </div>
                }

            </div>

            {
                JSON.stringify(carrito) === "[]" ? <></>
                    : <div className='carritoContainer__subTotal'>
                        <h6>Total: {formateaMoneda(costoCarrito + costoDelEnvioGratis)}</h6>
                    </div>
            }

            <div className='carritoContainer__medioDePago'>
                <p>Medio de pago: </p>
                <select className='mediodepago' defaultValue={medioDePago} onChange={(e) => { setMedioDePago(e.target.value); setTimeout(() => { setSeModificoMedioPago(true); }, 1000); }}>
                    <option value={mediosDePagoDisponibles[0]}>{mediosDePagoDisponibles[0]}</option>
                    <option value={mediosDePagoDisponibles[1]}>{mediosDePagoDisponibles[1]}</option>
                    <option value={mediosDePagoDisponibles[2]}>{mediosDePagoDisponibles[2]}</option>
                </select>
                <span className={`medioDePagoArrepentimiento${seModificoMedioPago === true ? '' : ' oculto'}`} >*Si luego de la compra queres modificarlo comunicate con nosotros!</span>
            </div>

            {
                isAuthenticated === false
                    ? <Link to="/user" className='botonFinalizarCompra noLogueado'>Inicia sesión para poder continuar la compra</Link>
                    : datosUsuarioContext.correo === undefined
                        ? <Link to="/user" className='botonFinalizarCompra noLogueado'>Ingresá un correo</Link>
                        : <button id="botonFinalizarCompra" disabled={JSON.stringify(carrito) === "[]"} className="botonFinalizarCompra" onClick={handleFinalizarCompra}>Finalizar Compra</button>
            }
        </div>
    );
}


export default CarritoContainer;