import BeatLoader from "react-spinners/BeatLoader";
import { useEffect, useState, useContext } from "react"

//TODO: re-agregar, sacar del commit de enero
//import { useParams } from "react-router-dom";
import ItemList from "./ItemList"
import ItemDetailContainer from "./ItemDetailContainer";
//import CategoriesContainer from "./CategoriesContainer";

import { collection, getDocs, query } from "firebase/firestore"
import { db } from "./Firebase";
import { contexto } from "./CustomProvider";
import { esProduccion } from "./utils";

const ItemListContainer = (props) => {
    const { setProductosTodos } = useContext(contexto);
    const renderIsDetails = props.render === 'detalle' || false;
    //const renderIsCategories = props.render === 'categoria' || false;
    //const { categoria } = useParams();

    const [productos, setProductos] = useState([]);
    const [estanProductosCargados, setEstanProductosCargados] = useState(false);

    useEffect(() => {
        //setEstanProductosCargados(false);
        const productosCollection = collection(db, "productos")
        let filtro = query(productosCollection)

        // if (categoria !== undefined) {
        //     filtro = query(productosCollection, where("category", "array-contains", categoria))
        // }

        getDocs(filtro)
            .then((respuesta) => {
                // setTimeout(() => {
                //     setEstanProductosCargados(true);
                //     setProductos(productos);
                //     setProductosTodos(productos);
                // }, 1000);

                const productos = respuesta.docs.map(doc => ({ ...doc.data() }));

                //const momentoInicio = new Date();
                if (esProduccion() === false) {
                    console.time();
                }

                Promise.all(productos.map(e => {
                    return fetch('https://api.mercadolibre.com/items/' + e.idMeli)
                        .then((response) => {
                            return response.json();
                        })
                        .then(data => {
                            e.imgMeliUrl = data['pictures'];
                            e.video = data['video_id'];
                            e.popularidad = data['sold_quantity'];
                            const precioMeli = data['original_price'] || data['price'];

                            e.price = Math.floor(0.8 * parseInt(precioMeli));
                            if (e.fullFilment === true) {
                                e.price = Math.floor(1 * parseInt(data['price']));          //para que cuando haya una oferta traiga ese precio (aunque no necesariamente es el que aparece)
                                e.stock = parseInt(data['available_quantity']);             //Sólo productos full para ver si están activos o no; porque no es el valor de stock real.
                            }
                        })

                }))
                    .then(() => {
                        setEstanProductosCargados(true);
                        setProductos(productos);
                        setProductosTodos(productos);
                        if (esProduccion() === false) {
                            console.timeEnd();
                        }
                    });
            })
            .catch((error) => {
                console.log(error)
            });

    }, []);


    if (!estanProductosCargados) {
        return (
            <div className="spinner">
                <BeatLoader color="#36d7b7" loading={true} />
            </div>);
    }

    if (renderIsDetails === false) {
        return (
            <ItemList productos={productos} />
        );
    }

    if (renderIsDetails === true) {
        return (
            <ItemDetailContainer productos={productos} />
        );
    }
}

export default ItemListContainer;