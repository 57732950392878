import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// Import the functions you need from the SDKs you need
import { Auth0Provider } from '@auth0/auth0-react';
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from './components/Firebase';


initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="dev-eevpki3wx8j8g2s5.us.auth0.com"
    clientId="LV6AXDHXQg37UUVIwDoMEKiLdSIgyL7b"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/user`
    }}
  >
    <App />
  </Auth0Provider>
);
