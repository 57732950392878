import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


export const firebaseConfig = {
  apiKey: "AIzaSyCU7C2KFWcVZur1G-yfQ0GPkvlHJ3OjzSI",
  authDomain: "lu4ult-a15d7.firebaseapp.com",
  projectId: "lu4ult-a15d7",
  storageBucket: "lu4ult-a15d7.appspot.com",
  messagingSenderId: "559288799359",
  appId: "1:559288799359:web:5a3a91260acc6a3cf72f4a",
  measurementId: "G-1YKNPTD720"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);