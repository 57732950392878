import { doc, getDoc, setDoc, getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import uuid from "react-uuid";
import { db } from "./Firebase";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useState, useEffect, useContext } from "react";

import { BeatLoader } from "react-spinners";
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import { contexto } from "./CustomProvider";
import { notiflixPersonalizacion, formateaMoneda, notificarMePorWhatsapp, isMobile, esProduccion, monospace, fechaTimestampAHumano, fechaFirestoreAFechaJS } from "./utils";
import CaraTristeAnimacion from "./CaraTristeAnimacion";
import { iconoWhatsapp, iconoTrash, iconoClipboard, iconoCheck } from "./Iconos";
import { Confirm } from 'notiflix';


const UserData = () => {
    const { setDatosUsuarioContext } = useContext(contexto);

    const { user, isAuthenticated, isLoading } = useAuth0();
    const [datosUsuario, setDatosUsuario] = useState({});
    //const [timeOutId, setTimeOutId] = useState();
    const [datosLeidosFirestore, setDatosLeidosFirestore] = useState(false);
    const provinciasLista = ["BUENOS AIRES", "CAPITAL FEDERAL", "CATAMARCA", "CHACO", "CHUBUT", "CORDOBA", "CORRIENTES", "ENTRE RIOS", "FORMOSA", "JUJUY", "LA PAMPA", "LA RIOJA", "MENDOZA", "MISIONES", "NEUQUEN", "RIO NEGRO", "SALTA", "SAN JUAN", "SAN LUIS", "SANTA CRUZ", "SANTA FE", "SANTIAGO DEL ESTERO"];
    const [ordenesDelUsuario, setOrdenesDelUsuario] = useState([]);

    const [showIconoClipboard, setShowIconoClipboard] = useState(iconoClipboard);


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            if (datosLeidosFirestore === false) {
                const biciRef = doc(db, "usuarios", user.sub);
                getDoc(biciRef).then(snapshot => {
                    if (snapshot.exists()) {
                        setDatosUsuario({ ...snapshot.data() });
                        setDatosLeidosFirestore(true);
                    }
                    else {
                        setDatosUsuario({ ...user, debeNotificarAndreani: true });
                        const dbSet = getFirestore();
                        setDoc(doc(dbSet, "usuarios", user.sub), { ...user }, { merge: true });
                        notificarMePorWhatsapp(`Primer inicio de ${user.name} en ${window.location.origin}`)
                    }
                })


                const ordenesDelUsuario = [];
                const userOrders = collection(db, "ordenes")
                const filtro = query(userOrders, where("user_sub", "==", user.sub.toString()))
                getDocs(filtro)
                    .then((respuesta) => {
                        respuesta.docs.forEach(order => {
                            ordenesDelUsuario.push({ ...order.data(), id: order.id, andreaniTexto: undefined });
                        })
                        ordenesDelUsuario.sort((a, b) => b.fecha - a.fecha);

                        Promise.all(ordenesDelUsuario.map(orden => {
                            if (orden.trackingNumber.substring(0, 5) === '36000') {
                                return fetch(`https://apidestinatarios.andreani.com/api/envios/${orden.trackingNumber}/trazas`)
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then(data => {
                                        //console.log(data)
                                        //orden.andreaniTexto = data[0];
                                        orden.andreaniTexto = `${data[0].fecha.dia || ''} ${data[0].fecha.hora || ''}: ${data[0].estado || ''}`;
                                        //orden.andreaniTexto = data[0].estado || "nada";
                                        //console.log(data)
                                    })
                            }
                        }))
                            .then(() => {
                                setOrdenesDelUsuario([...ordenesDelUsuario]);
                            })
                            .catch(error => console.log(error))
                    })
            }
        }
    }, [isAuthenticated]);


    useEffect(() => {
        if (datosUsuario.piso === '') {
            datosUsuario.piso = '-';
        }
        if (datosUsuario.unidad === '') {
            datosUsuario.unidad = '-';
        }


        if (JSON.stringify(datosUsuario) !== "{}") {
            setDatosUsuarioContext(datosUsuario);
            // clearTimeout(timeOutId)
            // const temporizadorId = setTimeout(() => {
            //     setDoc(doc(db, "usuarios", datosUsuario.sub), datosUsuario, { merge: true })
            //     setDatosUsuarioContext(datosUsuario);
            // }, 1000);

            // setTimeOutId(temporizadorId);
        }
    }, [datosUsuario]);

    const handleCancelarCompra = (orden) => {
        if (orden.estado !== "Procesando") {
            alert(`Esta orden se encuentra ${orden.estado} y no se puede cancelar. Cualquier consulta no dudes en comunicarte con nosotros.`)
            return;
        }

        Confirm.show(
            'Seguro??',
            `Vas a cancelar esta orden`,
            'Si, cancelar',
            'No,mantenerla',
            () => {
                setTimeout(() => {
                    const ordenesCopia = ordenesDelUsuario.filter(or => or !== orden);
                    orden.estado = "Cancelar";
                    setOrdenesDelUsuario([orden, ...ordenesCopia]);

                    setDoc(doc(db, "ordenes", orden.id), orden, { merge: true });
                }, 1);
            }
        );
    }

    const handleClipboardClick = (tn) => {
        if (navigator.clipboard?.writeText) {
            navigator.clipboard.writeText(tn);
            setShowIconoClipboard(iconoCheck);

            if (isMobile() === false) {
                Notify.success('TN Copiado al portapapeles!', notiflixPersonalizacion());
            }

            setTimeout(() => {
                setShowIconoClipboard(iconoClipboard);
            }, 1000);
            navigator.vibrate(100);
        }
    }

    const handleFormChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        document.activeElement.classList.remove('form-error');

        if (key === 'codarea') {
            if (value[0] === '0' && value.length >= 2) {
                value = value.substring(1, value.length);
            }
        }

        if (key === 'cel') {
            if (value.substring(0, 2) === '15') {
                Notify.info('Celular sin 15 al inicio!', { notiflixPersonalizacion });
                document.activeElement.classList.add('form-error');
            }
        }

        if (value[0] === ' ') {
            console.log("nop")
            value = value.substring(1, value.length)
        }

        const copiaDatosUsuario = {
            ...datosUsuario,
            [key]: e.target.type === 'checkbox' ? e.target.checked : value
        };

        setDatosUsuario({ ...copiaDatosUsuario })
    };

    const handleAutoComplete = () => {
        const copiaDatosUsuario = { ...datosUsuario };
        //copiaDatosUsuario['localidad'] = data.address.city;
        copiaDatosUsuario['nombre'] = user.given_name;
        copiaDatosUsuario['apellido'] = user.family_name;
        copiaDatosUsuario['correo'] = user.email;
        if (esProduccion() === false)
            console.table(user)

        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            //console.log(`${latitude}, ${longitude}`)
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    //console.log(data)
                    if (esProduccion() === false)
                        console.table(data.address)

                    copiaDatosUsuario['localidad'] = data.address.city;
                    copiaDatosUsuario['provincia'] = data.address.state.replace('ó', 'o').toUpperCase();
                    copiaDatosUsuario['cp'] = data.address.postcode.replace(/\D/g, '');

                })
                .catch(error => console.log(error))
                .finally(() => { setDatosUsuario(copiaDatosUsuario); })
            //console.log(url)
        })
    }

    const validarFormulario = () => {
        let inputsDelForm = [...document.querySelectorAll('input')];

        inputsDelForm = inputsDelForm.filter(i => i.name !== 'piso' && i.name !== 'unidad' && i.name !== 'cuit' && i.name !== 'iva')


        let tieneAlgunError = false;
        inputsDelForm.forEach(e => {
            e.classList.remove('form-error')
            //console.log(`${e.value.length}: ${e.id}-${e.name} ${e.value}`)
            if (e.value.length === 0) {
                tieneAlgunError = true;
                e.classList.add('form-error')
                //alert(`Ups! ${e.name.toUpperCase()} no puede estar vacío!`)
                Notify.failure(`Ups! ${e.name.toUpperCase()} no puede estar vacío!`,)
            }
            //}
        })

        if (tieneAlgunError === false) {
            Notify.success('Datos Actualizados', notiflixPersonalizacion());
        }
    }

    //console.log(ordenesDelUsuario[0].andreaniTexto)
    return (
        <div className="userData-container">
            {
                isAuthenticated === false ?
                    isLoading ? <div className="spinner"><BeatLoader color="#36d7b7" loading={true} /></div>
                        : <LoginButton />
                    : <>
                        <div className="userData">
                            <div className="img-container">
                                <img src={datosUsuario.picture} alt="Foto perfil"></img>
                            </div>
                            <p>{datosUsuario.nombre} {datosUsuario.apellido}</p>
                            {/* <p>{datosUsuario.email}</p> */}
                            <LogoutButton />
                        </div>
                        <div className="userOrders">
                            {
                                ordenesDelUsuario.length === 0 ?
                                    <div className="sinCompras-container">
                                        <CaraTristeAnimacion mensaje="Ninguna compra!" />
                                    </div>

                                    : <>
                                        {
                                            ordenesDelUsuario.map((orden, indice) => (
                                                // <div key={uuid()} className={`orden ${indice % 2 ? "impar" : "par"}`} id={orden.id}>
                                                <details open={orden.estado !== 'Finalizada'} key={uuid()} className={`orden ${indice % 2 ? "impar" : "par"}`} id={orden.id}>
                                                    <summary>{fechaTimestampAHumano(fechaFirestoreAFechaJS(orden.fecha).getTime())}</summary>

                                                    <div className="renglon"><span>ID:</span><span>{orden.id}</span></div>
                                                    <div className="renglon"><span>Productos:</span><span>{orden.totalProductos}</span></div>
                                                    <div className="renglon"><span>Precio:</span><span>{formateaMoneda(orden.totalCosto)}</span></div>
                                                    <div className="renglon">
                                                        {
                                                            orden.andreaniTexto === undefined
                                                                ? <a className="trackingNumber" href={'https://www.correoargentino.com.ar/formularios/e-commerce'} target="_blank" rel="noopener noreferrer">{orden.trackingNumber || "Pendiente"}</a>
                                                                : <a className="trackingNumber" href={`https://www.andreani.com/#!/informacionEnvio/${orden.trackingNumber || ""}`} target="_blank" rel="noopener noreferrer">{orden.andreaniTexto || "Pendiente"}</a>


                                                        }
                                                        <button className="clipboard primerIcono" onClick={() => { handleClipboardClick(orden.trackingNumber) }}>{showIconoClipboard}</button>
                                                    </div>
                                                    <div className="renglon">{orden.estado}</div>
                                                    <div className="botones-contenedor">
                                                        <button className={`orden-cancelacion${indice === 0 ? " primerIcono" : ""}`} onClick={() => { handleCancelarCompra(orden) }}>{iconoTrash}</button>
                                                        <a className={`whatsapp-consulta${indice === 0 ? " primerIcono" : ""}`}
                                                            href={`https://wa.me/542954692293?text=Quería consultar sobre mi compra:%0a*${monospace}${encodeURIComponent(orden.id)}${monospace}*%0aCon fecha:%0a${monospace}${encodeURIComponent(fechaTimestampAHumano(fechaFirestoreAFechaJS(orden.fecha).getTime()))}${monospace}`} target="_blank" rel="noopener noreferrer">
                                                            {iconoWhatsapp}
                                                        </a>
                                                    </div>
                                                </details>
                                            ))
                                        }
                                    </>
                            }

                        </div>
                        <form>
                            <h3>Datos para el envío</h3>
                            {
                                datosUsuario.correo === undefined || datosUsuario.correo === ""
                                    ? <button className="botonAutoCompletar" onClick={() => { handleAutoComplete(); }}>Autocompletar</button>
                                    : null
                            }
                            <div className="form__field">
                                <input placeholder="correo@test.com" type="email" name="correo" value={datosUsuario.correo} required onChange={handleFormChange} maxLength="40"></input>
                                <label>Tu dirección de correo electrónico</label>
                            </div>

                            <div className="form__field">
                                <input placeholder={datosUsuario.apellido} disabled={false} type="text" name="apellido" value={datosUsuario.apellido} onChange={handleFormChange} maxLength="40"></input>
                                <label>Tu apellido(s)</label>
                            </div>

                            <div className="form__field">
                                <input placeholder={datosUsuario.nombre} disabled={false} type="text" name="nombre" value={datosUsuario.nombre} onChange={handleFormChange} maxLength="40"></input>
                                <label>Tu nombre(s)</label>
                            </div>

                            <div className="form__field">
                                <input id="firstInputField" placeholder={datosUsuario.dni} type="number" name="dni" value={datosUsuario.dni} onChange={handleFormChange} maxLength="10"></input>
                                <label>DNI</label>

                            </div>

                            <div className="form__field">
                                <input placeholder="Seleccione de lista" list="provincias" name="provincia" id="provinciaa" value={datosUsuario.provincia} onChange={handleFormChange}></input>
                                <datalist id="provincias">
                                    {
                                        provinciasLista.map(prov => {
                                            return (<option value={prov} key={prov}></option>)
                                        })

                                    }
                                </datalist>
                                <label>Provincia</label>
                            </div>

                            <div className="form__field">
                                <input type="text" name="localidad" value={datosUsuario.localidad} onChange={handleFormChange} maxLength="30"></input>
                                <label>Localidad</label>
                            </div>
                            <div className="form__field">
                                <input type="text" name="calle" value={datosUsuario.calle} placeholder={datosUsuario.calle || "Calle"} onChange={handleFormChange} maxLength="30"></input>
                                <label>Calle</label>
                            </div>
                            <div className="form__field">
                                <input type="text" name="altura" value={datosUsuario.altura} placeholder={datosUsuario.altura || "Altura"} onChange={handleFormChange} maxLength="30"></input>
                                <label>Altura</label>
                            </div>
                            <div className="form__field">
                                <input type="text" name="piso" value={datosUsuario.piso} placeholder={datosUsuario.piso || "11"} onChange={handleFormChange} maxLength="10"></input>
                                <label>Piso (si es departamento)</label>
                            </div>
                            <div className="form__field">
                                <input type="text" name="unidad" value={datosUsuario.unidad} placeholder={datosUsuario.unidad || "B"} onChange={handleFormChange} maxLength="5"></input>
                                <label>Unidad (si es departamento)</label>
                            </div>
                            <div className="form__field">
                                <input type="number" name="cp" value={datosUsuario.cp} placeholder={datosUsuario.cp || "1234"} onChange={handleFormChange} maxLength="4"></input>
                                <label><a href="https://www.correoargentino.com.ar/formularios/cpa" target="_blank" rel="noopener noreferrer">Código Postal. Si no lo conoce puede buscarlo aquí</a></label>
                            </div>

                            <div className="form__field">
                                <input type="number" name="codarea" value={datosUsuario.codarea} placeholder={datosUsuario.codarea || "11"} onChange={handleFormChange} maxLength="5"></input>
                                <label>Teléfono - Sólo código de área</label>
                            </div>
                            <div className="form__field">
                                <input type="number" name="cel" value={datosUsuario.cel} placeholder={datosUsuario.cel || "123456"} onChange={handleFormChange} maxLength="20"></input>
                                <label>Teléfono</label>
                            </div>
                            <div className="form__field">
                                <input list="paqartrack" name="trackCode" value={datosUsuario.trackCode} placeholder={datosUsuario.trackCode || "Seleccione de la lista"} onChange={handleFormChange}></input>
                                <datalist id="paqartrack">
                                    <option value="Email"></option>
                                    <option value="Whatsapp"></option>
                                    <option value="Ambos"></option>
                                </datalist>
                                <label>Cómo le enviamos el código de tracking?</label>
                            </div>

                            <div className="form__field">
                                <input type="checkbox" name="esNotificarAndreani" defaultChecked={datosUsuario.esNotificarAndreani || true} onChange={handleFormChange}></input>
                                <label>¿Desea recibir notificaciones en Whatsapp sobre el envío (sólo para Andreani)</label>
                            </div>

                            <h5>Datos Facturación</h5>
                            <div className="form__field">
                                <input type="number" name="cuit" value={datosUsuario.cuit} placeholder={datosUsuario.cuit || "20-12345678-0"} onChange={handleFormChange} maxLength="20"></input>
                                <label>CUIT. Si es consumidor final dejar en blanco</label>
                            </div>
                            <div className="form__field">
                                <input list="ivas" name="iva" value={datosUsuario.iva} placeholder={datosUsuario.iva || "Seleccione de la lista o dejar en blanco"} onChange={handleFormChange}></input>
                                <datalist id="ivas">
                                    <option value="Consumidor Final"></option>
                                    <option value="Responsable Inscripto"></option>
                                    <option value="Excento"></option>
                                    <option value="Monotributo"></option>
                                </datalist>
                                <label>Condición frente al IVA</label>
                            </div>

                            <button type="button" className="submitButton" onClick={validarFormulario}>Guardar mis datos</button>
                            {/* <input type="button" className="submitButton" value="Guardar mis datos"></input> */}
                        </form>
                    </>
            }
        </div>
    );
}

export default UserData;